import { first, last } from 'lodash';

const validDate = (date) => {
  const today = new Date();
  const eventDate = new Date(date);
  // calculate if date is bigger than 18 years old
  const age = today.getFullYear() - eventDate.getFullYear();

  return eventDate < today && age >= 18;
};

export function validate(values) {
  const errors = {};

  for (let val in values) {
    if (
      !values[val] &&
      val !== 'identification' &&
      val !== 'sexualOrientation' &&
      val !== 'disability' &&
      val !== 'howManyPeople' &&
      val !== 'disabilityType'
    ) {
      errors[val] = 'Este campo es requerido';
    }
    if (val === 'birthDate' && !validDate(values[val])) {
      errors[val] = 'La fecha no es válida';
    }
  }
  return errors;
}

export const getValue = (scale) => {
  if ((scale < -0.1 && scale > -1) || (scale > 0.1 && scale < 1)) return 'LEVE';
  if ((scale < -1 && scale > -2) || (scale > 1 && scale < 2)) return 'MODERADO';
  if ((scale < -2 && scale > -3) || (scale > 2 && scale < 3)) return 'FUERTE';
  if (scale > -0.1 && scale < 0.1) return 'EQUILIBRADA';
};

const getEfectoRAText = (value) => {
  if (value >= -10 && value <= -2)
    return 'Tus respuestas en promedio sugirieron una FUERTE tendencia a vincular C-Level con Masculino-Hard por sobre  C-Level -Femenino Hard o Soft';
  if (value >= -2 && value <= -1)
    return 'Tus respuestas en promedio sugirieron una MODERADA tendencia a vincular C-Level con Masculino-Hard por sobre  C-Level -Femenino Hard o Soft';
  if (value >= -1 && value <= -0.09999)
    return 'Tus respuestas en promedio sugirieron una LEVE tendencia a vincular C-Level con Masculino-Hard por sobre  C-Level -Femenino Hard o Soft';
  if (value >= -0.1 && value <= 0.09999) return 'FELICITACIONES. Tu resultado es EQUILIBRADO.';
  if (value >= 0.1 && value <= 1)
    return 'Tus respuestas en promedio sugirieron una LEVE tendencia a vincular C-Level con Masculino-Hard por sobre  C-Level -Femenino Hard o Soft';
  if (value >= 1 && value <= 2)
    return 'Tus respuestas en promedio sugirieron una MODERADA tendencia a vincular C-Level con Masculino-Hard por sobre  C-Level -Femenino Hard o Soft';
  if (value >= 2 && value <= 3)
    return 'Tus respuestas en promedio sugirieron una FUERTE tendencia a vincular C-Level con Masculino-Hard por sobre  C-Level -Femenino Hard o Soft';
};

const getGeneralJRAText = (value) => {
  if (value >= -10 && value <= -2)
    return 'Tus respuestas sugieren una FUERTE tendencia a vincular Masculino a C-Level-Hard y Femenino a Colaborador-Soft';
  if (value >= -2 && value <= -1)
    return 'Tus respuestas sugieren una MODERADA tendencia a vincular Masculino a C-Level-Hard y Femenino a Colaborador-Soft.';
  if (value >= -1 && value <= -0.09999)
    return 'Tus respuestas sugieren una LEVE tendencia a vincular Masculino a C-Level-Hard y Femenino a Colaborador-Soft.';
  if (value >= -0.1 && value <= 0.09999)
    return 'Felicitaciones tu resultado es EQUILIBRADO. Esto significa que tus respuestas automáticas no se inclinan por ninguna de las categorías de la evaluación. Podría significar que tu cerebro ha trascendido los modelos culturales aceptados y puedes ver el talento de las personas más allá de cualquier característica.';
  if (value >= 0.1 && value <= 1)
    return 'Tus respuestas sugieren una LEVE tendencia a vincular Femenino a C-Level- Hard y Masculino a Colaborador-Soft.';
  if (value >= 1 && value <= 2)
    return 'Tus respuestas sugieren una MODERADA tendencia a vincular Femenino a C-Level-Hard y Masculino a Colaborador-Soft.';
  if (value >= 2 && value <= 3)
    return 'Tus respuestas sugieren una FUERTE tendencia a vincular Femenino a C-Level- Hard y Masculino a Colaborador-Soft.';
};

const getPositionGenderText = (value) => {
  if (value >= -10 && value <= -2)
    return 'Tus respuestas sugieren una FUERTE tendencia a vincular C-Level con Masculino y Femenino a Colaborador.';
  if (value >= -2 && value <= -1)
    return 'Tus respuestas sugieren una MODERADA tendencia a vincular C-Level  con Masculino y Femenino a Colaborador.';
  if (value >= -1 && value <= -0.09999)
    return 'Tus respuestas sugieren una LEVE tendencia a vincular C-Level con Masculino y Femenino a Colaborador.';
  if (value >= -0.1 && value <= 0.09999)
    return 'Felicitaciones tu resultado es EQUILIBRADO. Esto significa que tus respuestas automáticas no se inclinan por ninguna de las categorías de la evaluación. Podría significar que tu cerebro ha trascendido los modelos culturales aceptados y puedes ver el talento de las personas más allá de cualquier característica.';
  if (value >= 0.1 && value <= 1)
    return 'Tus respuestas sugieren una LEVE tendencia a vincular C-Level con Femenino y Colaborador a Masculino.';
  if (value >= 1 && value <= 2)
    return 'Tus respuestas sugieren una MODERADA tendencia a vincular C-Level con Femenino y Colaborador a Masculino.';
  if (value >= 2 && value <= 3)
    return 'Tus respuestas sugieren una FUERTE tendencia a vincular C-Level con Femenino y Colaborador a Masculino.';
};

const getHardSoftText = (value) => {
  if (value >= -10 && value <= -2)
    return 'Tus respuestas sugieren una FUERTE tendencia a vincular Masculino a Hard y Femenino a Soft.';
  if (value >= -2 && value <= -1)
    return 'Tus respuestas sugieren una MODERADA tendencia a vincular Masculino a Hard y Femenino a Soft.';
  if (value >= -1 && value <= -0.09999)
    return 'Tus respuestas sugieren una LEVE tendencia a vincular Masculino a Hard y Femenino a Soft.';
  if (value >= -0.1 && value <= 0.09999)
    return 'Felicitaciones tu resultado es EQUILIBRADO. Esto significa que tus respuestas automáticas no se inclinan por ninguna de las categorías de la evaluación. Podría significar que tu cerebro ha trascendido los modelos culturales aceptados y puedes ver el talento de las personas más allá de cualquier característica.';
  if (value >= 0.1 && value <= 1)
    return 'Tus respuestas sugieren una LEVE tendencia a vincular Femenino a Hard y Masculino a Soft.';
  if (value >= 1 && value <= 2)
    return 'Tus respuestas sugieren una MODERADA tendencia a vincular Femenino a Hard y Masculino a Soft.';
  if (value >= 2 && value <= 3)
    return 'Tus respuestas sugieren una FUERTE tendencia a vincular Femenino a Hard y Masculino a Soft.';
};

export const getScaleText = (value, index) => {
  switch (index) {
    case -1:
      return getEfectoRAText(value);
    case 0:
      return getPositionGenderText(value);
    case 1:
      return getHardSoftText(value);
    case 2:
      return getGeneralJRAText(value);

    default:
      return '';
  }
};

export const getJRAScalesTitles = (index) => {
  switch (index) {
    case 0:
      return `Resultado categorías:  \nC- Level- Colaborador/a vs.  Femenino - Masculino`;
    case 1:
      return `Resultados categorías: \nHard y Soft vs Femenino y Masculino`;
    case 2:
      return `Esta es la tendencia al aprendizaje`;
    default:
      return '';
  }
};

export const getFixedNumber = (value) => {
  if (!value) return;
  return Math.floor(value) === value ? Number(value) : Number(value.toFixed(2));
};

Number.prototype.isBetween = function (a, b) {
  let min = Math.min(a, b),
    max = Math.max(a, b);

  return this > min && this <= max;
};

export const SEGMENT_COLORS = [
  '#E53D3D',
  '#EAA73E',
  '#F8DA49',
  '#8DB944',
  '#F8DA49',
  '#EAA73E',
  '#E53D3D'
];

export const getResultColor = (
  value,
  {
    segments = [-3, -2, -1, -0.1, 0.1, 1, 2, 3],
    minValue = -3,
    maxValue = 3,
    colors = SEGMENT_COLORS
  } = {}
) => {
  let sanitizedValue = value || 99;
  if (sanitizedValue < minValue) {
    sanitizedValue = minValue;
  }
  if (sanitizedValue > maxValue) {
    sanitizedValue = maxValue;
  }
  let labelBackgroundColor = 'bg-secondary';
  if (sanitizedValue <= first(segments)) {
    labelBackgroundColor = first(colors);
  } else if (sanitizedValue >= last(segments)) {
    labelBackgroundColor = last(colors);
  } else {
    for (let i = 0; i < segments.length; i++) {
      const segment = segments[i];
      if (sanitizedValue < segment) {
        labelBackgroundColor = colors[i - 1];
        break;
      }
    }
  }

  return labelBackgroundColor;
};

export const isColorHex = (color) => new RegExp(/[0-9A-Fa-f]{6}/g).test(color);
